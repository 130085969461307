<script setup>
import { Link } from "@inertiajs/vue3";
import { computed } from "vue";
import { BUTTON_KINDS } from "@Res/js/constants";
import { isNullOrEmpty } from "../helpers";

const props = defineProps({
    href: String,
    as: {
        type: String,
        required: false,
    },
    linkAs: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        default: "link",
        validator(value) {
            return ["link", "button"].includes(value);
        },
    },
    fit: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: "md",
        validator(value) {
            return ["xs", "sm", "md", "lg", "xl"].includes(value);
        },
    },
    kind: {
        type: String,
        default: "primary",
        validator: (kind) => BUTTON_KINDS.includes(kind),
    },
});

const mainType = computed(() =>
    props.type === "button" || props.as === "button" ? "button" : "link"
);
</script>

<template>
    <Link
        :as="linkAs === as || isNullOrEmpty(linkAs) ? as : linkAs"
        :class="[
            `${mainType} ${mainType}-${kind}`,
            size ? `${mainType}-${size}` : '',
            { 'w-fit': fit },
            { 'rounded-full': rounded },
        ]"
        :href="href"
    >
        <slot />
    </Link>
</template>
